import $ from 'jquery';

import styles from '../sass/main.scss';

$(function() {

  $('.hamburger').click(function(e) {
    e.preventDefault();
    $('.nav-main .links').show();
    $('.nav-main .menu-close').show();
    $('.nav-main .menu-close').click(function (e) {
      e.preventDefault();
      $('.nav-main .links').hide();
    });
  });

  $('.employee-trigger').click(function(e) {
    e.preventDefault();
    $(this)
      .parent('.employee')
      .toggleClass('is-active');
  });

  $('.video-controls').click(function(e) {
    e.preventDefault();
    var video = $(this).siblings('video');
    if (
      $(this)
        .parent('.video')
        .hasClass('is-playing')
    ) {
      $(this)
        .parent('.video')
        .removeClass('is-playing');
      video.get(0).pause();
    } else {
      $(this)
        .parent('.video')
        .addClass('is-playing');
      video.get(0).play();
    }
  });

  $('#print-link').click(function(e) {
    window.print();
  });

  $('.icon-to-top').click(function() {
    var location = $(this).attr('data-scroll');
    scrollBody(location);
  });

  function scrollBody(location) {
    if (!$('html, body').is(':animated')) {
      $('html, body').animate({
        scrollTop: $(location).offset().top,
      });
    }
  }
});
